import { DesktopAdId } from '../../../contexts'
import { ILayout3, IPost } from '../../../types'
import Ad from '../../Ads/Ad'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Post } from '../../Post/Post'
import {
  Banner,
  Container,
  CustomIframe,
  PostsContainer,
} from './Layout3.styles'

type Props = {
  layout: ILayout3
}

export const Layout3 = ({ layout }: Props) => {
  const { grid_title, posts, layout_mobile, iframeUrl, isIframe, grid_id } =
    layout

  return posts ? (
    <Container>
      <ComponentTitle componentTitle={grid_title} componentId={grid_id} />
      <PostsContainer>
        {posts.map(
          (post: IPost, key: number) =>
            post.imageObj && (
              <Post
                key={key + ' ' + post.id}
                post={post}
                layoutMobile={layout_mobile}
                hideSecondaryTitle
              />
            )
        )}
        {isIframe && <CustomIframe src={iframeUrl} />}
        {!isIframe && (
          <Banner>
            <Ad id={layout.adUnit as DesktopAdId} collapse={false} />
          </Banner>
        )}
      </PostsContainer>
    </Container>
  ) : null
}
