import styled from 'styled-components'
import { colors, device, maxWidth } from '../../styles/GlobalVariables'
import { Container as BreadcrumbsContainer } from '../Breadcrumbs/Breadcrumbs.styles'
import {
  figureStyle,
  StyledIframeWrapper as IframeWrapper,
} from '../ContentParser/ContentParser.style'
import { Container as ShareButtons } from '../ShareButtons/ShareButtons.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  ${device.medium} {
    margin: 8px auto 0 auto;
    max-width: ${maxWidth.medium};
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
`

export const HorizontalContainer = styled.div`
  display: flex;
`

export const Sidebar = styled.div<{
  stickyShare: 'top' | 'bottom' | false
  headerHeight: number
}>`
  display: none;

  ${device.medium} {
    display: flex;
    justify-content: flex-end;
    margin-right: 85.7px;
  }

  ${device.large} {
    margin-right: 103.7px;
  }

  ${ShareButtons} {
    ${({ stickyShare, headerHeight }) =>
      stickyShare === 'top' && {
        position: 'fixed',
        top: `${headerHeight + 20}px`,
      }};

    ${({ stickyShare }) =>
      stickyShare === 'bottom' && { position: 'absolute', bottom: 0 }};
  }
`

export const ShareButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 16px;
  font-size: 38px;
  min-width: ${props => props.theme.size || 40}px;
  position: relative;

  svg {
    display: block;
  }

  ${ShareButtons} {
    flex-direction: column;
  }
`

export const FullWidth = styled.div``

export const Content = styled.article`
  display: flex;
  flex-direction: column;

  > * {
    margin-left: 20px;
    margin-right: 20px;

    ${device.medium} {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  > ${BreadcrumbsContainer} {
    margin-bottom: 16px;
  }

  > ${FullWidth} {
    margin-left: 0px;
    margin-right: 0px;
  }

  & iframe[title='YouTube video player'] {
    height: 460px;
  }

  ${device.medium} {
    flex-basis: 816px;
    flex-shrink: 0;
  }
`
export const TextContainer = styled.div`
  margin-bottom: 15px;
  ${device.medium} {
    margin-bottom: 40px;
  }
  > *:last-child {
    > * {
      margin-bottom: 0;
    }
    margin-bottom: 0;
  }

  a {
    color: ${colors.linkBlue};
    text-decoration: underline;
    &:hover {
      color: ${colors.headerBlue};
    }
    &:visited {
      color: ${colors.linkBlue};
    }
  }

  p {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 16px;

    ${device.medium} {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 20px;
    }

    iframe,
    ${IframeWrapper} {
      margin-top: 30px;
      margin-bottom: 14px;

      ${device.medium} {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }

  p > iframe,
  ${IframeWrapper} {
    margin-right: -20px;
    margin-left: -20px;
    max-width: 100vw;

    ${device.medium} {
      margin-right: unset;
      margin-left: unset;
      max-width: unset;
    }
  }
  figure {
    ${figureStyle};
  }

  blockquote:not(.twitter-tweet, .instagram-media) {
    display: flex;
    align-items: center;
    padding-right: 20px;
    border-right: 4px solid ${colors.darkBlue};
    margin: 30px 0;

    &,
    p {
      letter-spacing: 0.02em;
      color: ${colors.darkBlue};
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 0;

      ${device.medium} {
        font-size: 31px;
        line-height: 37px;
      }
    }

    ${device.medium} {
      margin: 40px 0;
    }
  }

  .twitter-tweet,
  .instagram-media {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h3 {
    font-weight: bold;
    color: ${colors.black};
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
    margin-bottom: 4px;

    ${device.medium} {
      font-size: 24px;
      line-height: 29px;
      margin-top: 40px;
      margin-bottom: 11px;
    }
  }

  ul,
  ol {
    margin-bottom: 30px;

    ${device.medium} {
      margin-bottom: 40px;
    }

    li {
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      :before {
        position: relative;
        top: 2px;
        font-size: 54px;
        line-height: 22px;
        letter-spacing: -6px;
        color: ${colors.darkBlue};
      }

      ${device.medium} {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      a {
        font-weight: bold;
        color: ${colors.darkBlue};
        text-decoration: none;
      }
    }
  }

  ol {
    li {
      display: list-item;
      margin-right: 22px;
      ::marker {
        color: #00104a;
      }
    }
  }

  ul {
    li {
      list-style-type: '•';
      :before {
        content: '•';
      }
    }
  }

  iframe {
    max-width: 100%;
  }

  > iframe,
  ${IframeWrapper}, .twitter-tweet {
    margin: 0 auto 12px !important;
  }
`

export const RecipeTextContainer = styled(TextContainer)`
  ul,
  ol {
    li {
      padding: 16px;
      padding-right: 8px;
      &:nth-child(2n + 1) {
        background-color: #f3f3f3;
      }
    }
  }
`

export const AdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 16px;

  ${device.medium} {
    margin-bottom: 20px;
  }

  > div {
    min-width: unset;

    p {
      margin: 0;
    }
  }

  > p {
    margin-bottom: 0;
  }
`

export const AdsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TaboolaLR = styled.div`
  max-width: 300px;
  min-width: 300px;
`

export const TaboolaWrapper = styled.div`
  margin: 0 20px;

  ${device.medium} {
    margin: unset;
  }
`
