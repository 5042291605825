import cx from 'classnames'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { canShare } from '../../client/canShare'
import { useHeader, usePage, useWindow } from '../../contexts'
import { he } from '../../locales/he'
import { colors, maxWidth, maxWidthFl } from '../../styles/GlobalVariables'
import {
  IBigBrotherButton,
  IHeader,
  IHeaderMenu,
  IOpenGraph,
} from '../../types'
import { urlsEqual } from '../../utils'
import Link from '../Link/Link'
import { LogoComp } from '../Logo'
import { utils } from '../Player/Helpers/utils'
import {
  Action,
  BBLiveIcon,
  ButtonsWrapper,
  Children,
  Container,
  DesktopMenu,
  Group,
  HamburgerIcon,
  HeaderContainer,
  Item,
  ItemsWrapper,
  LiveIcon,
  LogoWrapper,
  MenuArrow,
  MenuBack,
  MenuChild,
  MenuSearch,
  MobileMenu,
  SearchIcon,
  ShareWrapper,
  TransparentLogo,
  VodButton,
} from './Header.styles'
import { SubMenu } from './SubMenu'
import MenuArrowDesktopIcon from '/public/assets/images/svg/menu-arrow-desktop.svg'
import MenuArrowMobileIcon from '/public/assets/images/svg/menu-arrow-mobile.svg'
import MenuBackIcon from '/public/assets/images/svg/menu-back.svg'
import MenuCloseIcon from '/public/assets/images/svg/menu-close.svg'
import MenuOpenIcon from '/public/assets/images/svg/menu-open.svg'
import MenuSearchDarkIcon from '/public/assets/images/svg/menu-search-dark.svg'
import MenuSearchLightIcon from '/public/assets/images/svg/menu-search-light.svg'
import ShareIcon from '/public/assets/images/svg/share-header.svg'

type Props = {
  header: IHeader
  className?: string
  pageType: string
  ogData: IOpenGraph
  displaySubmenu: boolean
  bbButton?: IBigBrotherButton
}

export const Header = ({
  header,
  pageType,
  className,
  ogData,
  displaySubmenu,
  bbButton,
}: Props) => {
  const { asPath } = useRouter()
  const { setHeaderHeight } = useHeader()
  const headerRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const mobileMenuRef = useRef<HTMLDivElement>(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const { title } = ogData
  const win = useWindow()
  const page = usePage()

  const initialMenuState = {
    showingChildren: false,
    menuItems: header?.mainMenu,
    title: undefined,
  }

  const [opened, setOpened] = useState(false)
  const [menuState, setMenuState] = useState<{
    showingChildren: boolean
    menuItems: IHeaderMenu[]
    title?: string
  }>(initialMenuState)

  const handleMenuIconClick = useCallback(() => {
    setOpened(!opened)
    setMenuState(initialMenuState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  const handleMobileMenuClick = useCallback(
    (children: IHeaderMenu[], title?: string) => {
      setMenuState({
        showingChildren: !menuState.showingChildren,
        menuItems: children,
        title,
      })
    },
    [menuState]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !opened ||
        !mobileMenuRef.current ||
        mobileMenuRef.current.contains(event.target as Node)
      ) {
        return
      }
      setOpened(false)
    }

    const handleScroll = () => {
      setIsScrolled(utils.isScrolled())
    }

    document.addEventListener('mousedown', handleClickOutside)

    // TODO: Only do this on some pagetypes
    window.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)

      window.removeEventListener('scroll', handleScroll)
    }
  }, [mobileMenuRef, opened, pageType])

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
    }
  }, [headerRef, setHeaderHeight])

  const showShareIcon = pageType !== 'hp'
  const showLiveButton = true //pageType !== 'live'
  const showBBLive = bbButton?.link && bbButton.icon //&& pageType === 'hp'

  const { showingChildren, menuItems } = menuState

  const activeHeaderGroup = findActiveGroup(
    header?.mainMenu?.slice(0, 7),
    asPath
  )
  return (
    <>
      <Container
        className={cx(className, isScrolled && 'is-scrolled')}
        isFaceLift={page?.PageMeta.isFaceLift}
        id={'header'}
        displaySubmenu={displaySubmenu}
        ref={headerRef}
      >
        <HeaderContainer
          maxWidth={page?.PageMeta.isFaceLift ? maxWidthFl : maxWidth}
          displaySubmenu={displaySubmenu}
          theme={{ haseWarComp: page?.PageMeta.war_comp }}
        >
          <HamburgerIcon onClick={handleMenuIconClick}>
            {opened ? <MenuCloseIcon /> : <MenuOpenIcon />}
          </HamburgerIcon>
          <LogoComp />
          <DesktopMenu>
            <ItemsWrapper ref={menuRef}>
              {header?.mainMenu
                ?.slice(0, 7)
                ?.map(({ title, url, target, children }, index) => (
                  <Group
                    key={`menuItem-${index}`}
                    subItems={children.length > 0}
                  >
                    <Item iconSize={12} active={index === activeHeaderGroup}>
                      <Link href={url} target={target}>
                        {title}
                      </Link>
                      {children.length > 0 && <MenuArrowDesktopIcon />}
                    </Item>
                    {children.length > 0 && (
                      <Children
                        background={
                          page?.PageMeta.isFaceLift
                            ? colors.midnightBlue
                            : colors.headerBlue
                        }
                      >
                        {children.map(({ title, url, target }, index) => (
                          <MenuChild
                            href={url}
                            key={`desktopStyledLinkMenuChild-${index}`}
                            target={target}
                          >
                            <span>{title}</span>
                          </MenuChild>
                        ))}
                      </Children>
                    )}
                  </Group>
                ))}
            </ItemsWrapper>
          </DesktopMenu>
          <ButtonsWrapper>
            {!page?.PageMeta.isFaceLift &&
              !utils.isMobile() &&
              page?.SiteMeta.config?.features?.heyday === 'on' && (
                <SearchIcon key={'heydaySearch'} id='heydaySearch'>
                  <MenuSearchLightIcon />
                </SearchIcon>
              )}
            <VodButton href='/allshows' />
            {showLiveButton && (
              <>
                {showBBLive && (
                  <BBLiveIcon href={bbButton?.link || '/bb-livestream/'}>
                    <img
                      className='mobile'
                      src={
                        bbButton?.icon.mobile ||
                        'https://media.reshet.tv/image/upload/v1654619384/bigBrother/2022/eye_Live_Mobile.webp'
                      }
                      alt='האח הגדול לייב'
                    />
                    <img
                      className='desktop'
                      src={
                        bbButton?.icon.desktop ||
                        'https://media.reshet.tv/image/upload/v1654615533/bigBrother/2022/eye_Live.webp'
                      }
                      alt='האח הגדול לייב'
                    />
                  </BBLiveIcon>
                )}
                <LiveIcon href={'/live'}>
                  <span>LIVE</span>
                </LiveIcon>
                {showShareIcon && win && canShare() && (
                  <ShareWrapper
                    onClick={() => {
                      navigator.share({
                        title: title || '',
                        url: win.location.href,
                      })
                    }}
                  >
                    <ShareIcon />
                  </ShareWrapper>
                )}
              </>
            )}
          </ButtonsWrapper>
          {displaySubmenu && (
            <SubMenu
              subMenu={header.subMenu}
              isFaceLift={page?.PageMeta.isFaceLift}
            />
          )}
        </HeaderContainer>
        {!page?.PageMeta.isFaceLift && (
          <LogoWrapper>
            <TransparentLogo src='/assets/images/logo-header-transparent.png' />
          </LogoWrapper>
        )}
      </Container>
      <MobileMenu className={className} opened={opened} ref={mobileMenuRef}>
        {false && (
          <Action>
            <MenuSearch>
              <MenuSearchDarkIcon />
              {he.search}
            </MenuSearch>
          </Action>
        )}
        {showingChildren && (
          <Action>
            <MenuBack
              onClick={() => {
                handleMobileMenuClick(header.mainMenu)
              }}
            >
              <MenuBackIcon />
              {menuState.title || he.sections}
            </MenuBack>
          </Action>
        )}

        {menuItems?.map(({ title, url, target, children }, index) => (
          <Item
            key={`menuItem-${index}`}
            subItems={children.length > 0}
            onClick={() => {
              children.length > 0
                ? handleMobileMenuClick(children, title)
                : setOpened(false)
            }}
          >
            <Link href={url} target={target}>
              {title}
            </Link>
            {children.length > 0 && (
              <MenuArrow>
                <MenuArrowMobileIcon />
              </MenuArrow>
            )}
          </Item>
        ))}
      </MobileMenu>
    </>
  )
}

const findActiveGroup = (menuItems: IHeaderMenu[], asPath: string) => {
  const activeGroup = menuItems?.findIndex(({ url, children }) => {
    if (!children.length || !url) {
      return
    }
    return (
      urlsEqual(url, asPath) ||
      children.some(({ url }) => urlsEqual(url, asPath))
    )
  })
  return activeGroup !== -1 ? activeGroup : null
}
