import { useEffect, useMemo, useRef } from 'react'
import { useRootStore } from '../../store'
import { IPageMeta, IPageSEO } from '../../types'
import { DateTime } from '../../utils'
import { ShareButtons } from '../ShareButtons/ShareButtons'
import {
  ActiveLink,
  Container,
  CurrentDate,
  LeftColumnDesktop,
  RightColumn,
  TabNavNavigation,
  Title,
  TitleLink,
} from './PageTitle.styles'
import { useRouter } from 'next/router'
import { default as NextLink } from 'next/link'

type Props = {
  SEO: IPageSEO
  pageMeta: IPageMeta
  showCurrentDate?: boolean
  forceTitle?: string
}

export const PageTitle = ({
  SEO,
  pageMeta,
  showCurrentDate,
  forceTitle,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { setPageTitleRef } = useRootStore()
  const { asPath } = useRouter()
  const liveTab = useMemo(() => {
    const slugArr = asPath
      .split(/[?|#]/)[0]
      .split('/')
      .filter(slug => !!slug)
    if (slugArr.length === 1) {
      if (slugArr[0] === 'live') {
        return {
          title: 'האח הגדול לצפייה ישירה',
          link: '/bb-livestream',
          direction: 'normal',
          activeLink: 'live',
        }
      }
      if (slugArr[0] === 'bb-livestream') {
        return {
          title: 'LIVE ערוץ 13',
          link: '/live',
          direction: 'reverse',
          activeLink: 'bb',
        }
      }
    }
    return false
  }, [asPath])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    setPageTitleRef(containerRef)
  }, [containerRef, setPageTitleRef])

  if (!pageMeta.title) {
    return null
  }

  return (
    <Container ref={containerRef}>
      <RightColumn>
        <TabNavNavigation theme={{ direction: liveTab && liveTab.direction }}>
          <Title
            as={pageMeta?.isFaceLift ? 'h1' : 'div'}
            theme={{ liveTab: liveTab }}
          >
            {forceTitle || pageMeta.title}
            {liveTab && liveTab.activeLink && (
              <ActiveLink theme={{ color: liveTab.activeLink }} />
            )}
          </Title>
          {liveTab && (
            <NextLink href={liveTab.link}>
              <TitleLink>{liveTab.title}</TitleLink>
            </NextLink>
          )}
        </TabNavNavigation>
        {showCurrentDate && (
          <CurrentDate>{DateTime.today({ digitsOnly: true })}</CurrentDate>
        )}
      </RightColumn>
      <LeftColumnDesktop>
        <ShareButtons SEO={SEO} />
      </LeftColumnDesktop>
    </Container>
  )
}
