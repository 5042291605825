import { useCallback, useRef, useState } from 'react'
import { usePage } from '../../contexts'
import { IFrameBlock } from '../../types'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import { Container, CustomIframe } from './IFrame.styles'

type Props = {
  iFrame: IFrameBlock
}

export const IFrame = ({ iFrame }: Props) => {
  const { grid_title, grid_id, url, css } = iFrame
  const pageMeta = usePage()?.PageMeta

  const [height, setHeight] = useState<boolean | number>(false)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const onLoad = useCallback(() => {
    try {
      const urlEl = new URL(url)
      if (iframeRef !== null && urlEl.host === window.location.host) {
        try {
          if (iframeRef && iframeRef.current) {
            setHeight(
              iframeRef?.current?.contentWindow?.document?.body?.offsetHeight ||
                false
            )
          }
        } catch (e) {
          /* empty */
        }
      }
    } catch (e) {
      /* empty */
    }
  }, [url])

  return (
    <Container>
      <ComponentTitle
        componentTitle={grid_title}
        pageId={pageMeta?.id}
        componentId={grid_id}
      />
      <CustomIframe
        src={url}
        css={css}
        ref={iframeRef}
        onLoad={onLoad}
        theme={{ height }}
      />
    </Container>
  )
}
