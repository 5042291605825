import styled from 'styled-components'
import ZoomInSvg from '../../public/assets/images/svg/zoom-in.svg'
import { Gallery } from '../components/Gallery/Gallery'
import { useOverlay } from '../contexts'
import { device } from '../styles/GlobalVariables'

type Props = {
  index: number
}

export const ZoomIn = ({ index }: Props) => {
  const { setIsOpen: setIsOverlayOpen, setBody: setOverlayBody } = useOverlay()

  return (
    <ZoomInIcon
      onClick={() => {
        setIsOverlayOpen(true)
        setOverlayBody(<Gallery index={index} />)
      }}
    />
  )
}

const ZoomInIcon = styled(ZoomInSvg)`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 26px;
  cursor: pointer;

  ${device.medium} {
    top: 12px;
    right: 12px;
    font-size: 31px;
  }
`
