import { AdId } from '../../../contexts'
import { ILayout6 } from '../../../types'
import Ad from '../../Ads/Ad'
import ComponentTitle from '../../ComponentTitle/ComponentTitle'
import { Container, PostContainer, Poster, StyledPost } from './Layout6.styles'

type Props = {
  layout: ILayout6
}

export const Layout6 = ({ layout }: Props) => {
  const posts = layout.posts.slice(0, 6) // Only keep the first 6 posts
  const { grid_title, adUnit, layout_mobile, grid_id } = layout

  return posts ? (
    <Container>
      <ComponentTitle componentTitle={grid_title} />
      <PostContainer>
        {posts.map((post, index) => (
          <StyledPost
            key={index + '-' + post.id}
            layoutMobile={layout_mobile}
            post={post}
            height='307px'
            maxLines={3}
            grid_id={grid_id}
          />
        ))}
        <Poster key={grid_id}>
          <Ad id={adUnit as AdId} />
        </Poster>
      </PostContainer>
    </Container>
  ) : null
}
