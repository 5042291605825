import styled from 'styled-components'
import {
  colors,
  device,
  playButtonMainStyle,
  themeClassName,
} from '../../styles/GlobalVariables'
import ComponentTitle from '../ComponentTitle/ComponentTitle'
import Link from '../Link/Link'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 0 -20px 30px;

  ${device.medium} {
    margin: 0 0 28px;
  }
`

export const StyledComponentTitle = styled(ComponentTitle)`
  margin: 0 20px 20px;

  ${device.medium} {
    margin: 0 0 28px;
  }
`

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${device.medium} {
    flex-direction: row;
    gap: 0 28px;
  }
`

export const Thumbnail = styled(Link)`
  display: flex;
  position: relative;
  flex: 1;
  align-items: flex-start;
  height: fit-content;

  ${device.medium} {
    margin-left: 7px;
    flex-basis: 667px;
  }

  img {
    max-height: 210.9px !important;
    flex: 1 !important;
    object-fit: cover !important;

    ${device.medium} {
      min-height: 375px !important;
      max-height: 375px !important;
    }
  }

  ${playButtonMainStyle}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 20px 0;
  gap: 8px;

  ${device.medium} {
    flex-basis: 330px;
    max-width: 330px;
    padding: 0;
    gap: 12px;
  }
`

export const Title = styled.h3`
  color: ${colors.black};
  font-size: 28px;
  line-height: 1.2;
  font-weight: 900;

  max-height: calc(3 * 1.2 * 28px);
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    font-size: 38px;
    line-height: 1.18;
    max-height: calc(6 * 1.18 * 38px);
    -webkit-line-clamp: 6;
  }
`

export const SecondaryTitle = styled(Link)`
  color: ${colors.black};
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;

  max-height: calc(5 * 1.2 * 18px);
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  ${themeClassName.vodMain} & {
    color: ${colors.white};
  }

  ${device.medium} {
    flex-shrink: 0;
    line-height: 1.44;
    -webkit-line-clamp: 6;
    max-height: calc(6 * 1.44 * 18px);
  }
`

export const NewsflashesContainer = styled.div`
  display: flex;

  ${device.medium} {
    height: 391px;
  }
`
