import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { isVodMain, IVodPage } from '../../types'
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { EditMe } from '../EditMe'
import { commonItemToComponent, vodItemToComponent } from '../itemToComponent'
import { LazyLoader } from '../LazyLoader'
import { PageTitle } from '../PageTitle/PageTitle'
import { PocTaboolaPageType, PocTaboolaSuffixId, Taboola } from '../Taboola'
import { WordPressWrapper } from '../WordPressWrapper'
import { Container } from './VodPage.styles'

type Props = {
  page: IVodPage
}

export const VodPage = ({ page }: Props) => {
  const { SEO, PageMeta, Content, Header } = page
  const router = useRouter()
  const isVODHP = useMemo(
    () => PageMeta.pageType === 'hpvod',
    [PageMeta.pageType]
  )
  const firstTaboolaItemIndex = 2

  const disableTaboola = PageMeta.ads.block.taboola
  const pageGridItems = useMemo(() => {
    let t_index = 0
    return (
      Content.PageGrid?.map((item, index) => {
        return (
          <React.Fragment key={`vodGrid${index}`}>
            <WordPressWrapper item={item}>
              <EditMe grid_id={item.grid_id}>
                <LazyLoader>
                  {vodItemToComponent({ item: item, i: index }) ??
                    commonItemToComponent({ item: item, i: index })}{' '}
                </LazyLoader>
              </EditMe>
            </WordPressWrapper>
            {(() => {
              if (
                isVODHP &&
                !disableTaboola &&
                (index == firstTaboolaItemIndex ||
                  (index > 6 && (index - 2) % 5 === 0))
              ) {
                t_index += 1
                return (
                  <TaboolaWrapper>
                    <Taboola
                      pageType={PocTaboolaPageType.hpvod}
                      // key={'t' + t_index}
                      index={t_index}
                      suffixId={PocTaboolaSuffixId.widget}
                    />
                  </TaboolaWrapper>
                )
              }
              return null
            })()}
          </React.Fragment>
        )
      }) || []
    )
  }, [Content.PageGrid, disableTaboola, isVODHP])

  const taboolaFeed = useMemo(() => {
    if (disableTaboola) {
      return null
    }
    return (
      <TaboolaWrapper>
        <Taboola
          key={router.asPath}
          pageType={isVODHP ? PocTaboolaPageType.hpvod : PocTaboolaPageType.vod}
          suffixId={PocTaboolaSuffixId.pageFooter}
        />
      </TaboolaWrapper>
    )
  }, [disableTaboola, router.asPath, isVODHP])
  return (
    <Container>
      {!isVodMain(Content.PageGrid[0]) && (
        <>
          <PageTitle SEO={SEO} pageMeta={PageMeta} />
          <Breadcrumbs breadcrumbs={Header.breadCrumbs} />
        </>
      )}
      {pageGridItems}
      {taboolaFeed}
    </Container>
  )
}

const TaboolaWrapper = styled.div`
  margin-bottom: 17px;
`
