import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isIosApp } from '../../client'
import { he } from '../../locales/he'
import { IPage, isArticlePage } from '../../types'

type Props = {
  page: IPage
}

export const HeadTags = ({ page }: Props) => {
  const router = useRouter()
  const [isInIosApp, setIsInIosApp] = useState(false)

  const { robots, og } = page.SEO

  const image =
    og.image ||
    'https://media.reshet.tv/image/upload/t_og_image/v1633341900/assets/2021/default_image_tv.webp'

  const canRecommend =
    isArticlePage(page) && !page.Content.Item.video?.name.includes('episode')
      ? 'true'
      : 'false'

  useEffect(() => {
    if (!isIosApp()) {
      return
    }
    setIsInIosApp(true)
  }, [])

  return (
    <Head>
      <title>{page.SEO.title}</title>
      {!isInIosApp ? (
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      ) : (
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      )}
      <meta name='description' content={page.SEO.description || he.logoAlt} />
      <meta name='keywords' content={page.SEO.keywords || ''} />
      <meta name='robots' content={[robots.index, robots.follow].join(', ')} />
      <meta property='og:title' content={og.title} />
      <meta property='og:description' content={og.description} />
      <meta property='og:image' content={image} />
      {/*<meta property="og:image:secure_url" content={image} />*/}
      <meta property='og:image:width' content={og.image_width} />
      <meta property='og:image:height' content={og.image_height} />
      <meta property='og:url' content={og.url} />
      <meta property='og:type' content={og.type} />
      <meta property='og:site_name' content={og.site_name} />
      <meta property='og:locale' content={og.local} />
      <meta name='canRecommend' content={canRecommend} />
      <meta name='publishDate' content={page.PageMeta.publishDate} />
      <link
        rel='canonical'
        href={
          page?.SEO?.canonical || router.basePath + router.asPath.split('?')[0]
        }
      />
    </Head>
  )
}
