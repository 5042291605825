// import { InView } from 'react-intersection-observer'
// import { isDesktop } from '../client/getAdDeviceType'
// import { useWindow } from '../contexts'
// import { Spinner } from './Spinner'

type Props = {
  children: React.ReactNode
}

export const LazyLoader = ({ children }: Props) => {
  //   const onServerSide = !useWindow()
  //   const [isMobile, setIsMobile] = useState(false)
  //   const [isInView, setIsInView] = useState(false)

  //   useEffect(() => {
  //     setIsMobile(!isDesktop())
  //   }, [])

  return <>{children}</>
  // if (onServerSide || !isMobile || isInView) {
  // }

  // return (
  //   <InView onChange={inView => !isInView && setIsInView(inView)}>
  //     <Spinner />
  //   </InView>
  // )
}
