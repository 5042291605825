import { usePage } from '../../contexts'
import { IMainStandard } from '../../types'
import { addAnalyticsToUrl } from '../../utils'
import Credits from '../DateAndWriter/Credits'
import { Image } from '../Image/Image'
import Link from '../Link/Link'
import Newsflashes from '../Newsflashes/Newsflashes'
import {
  Container,
  NewsflashesContainer,
  PostContainer,
  SecondaryTitle,
  StyledComponentTitle,
  TextContainer,
  Thumbnail,
  Title,
} from './MainStandard.styles'
import PlayButton from '/public/assets/images/svg/play-button.svg'

type Props = {
  mainStandard: IMainStandard
}

export const MainStandard = ({ mainStandard }: Props) => {
  const page = usePage()
  if (mainStandard.posts === null) {
    return null
  }

  const post = mainStandard.posts[0]
  const linkTarget = undefined
  const { grid_title, newsFlash } = mainStandard

  const link = addAnalyticsToUrl(
    post?.link,
    page?.PageMeta.id.toString() as string,
    mainStandard.grid_id.toString()
  )
  return (
    <Container>
      <StyledComponentTitle componentTitle={grid_title} />
      <PostContainer>
        <TextContainer>
          {post.title && (
            <Link href={link} target={linkTarget}>
              <Title>{post.title}</Title>
            </Link>
          )}
          <SecondaryTitle href={link} target={linkTarget}>
            {post.secondaryTitle}
          </SecondaryTitle>
          <Credits credits={post} />
        </TextContainer>
        <Thumbnail href={post?.link} target={linkTarget}>
          <Image
            image={post.imageObj}
            alt={post.title || post.secondaryTitle}
          />
          {post.video && <PlayButton />}
        </Thumbnail>
        <NewsflashesContainer>
          <Newsflashes
            newsFlashColor={newsFlash.color}
            newsFlash={newsFlash.newsFlashArr}
          />
        </NewsflashesContainer>
      </PostContainer>
    </Container>
  )
}
export default MainStandard
