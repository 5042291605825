import { useEffect, useState } from 'react'
import { clientApi } from '../../client/clientApi'
import { he } from '../../locales/he'
import { INewsflash } from '../../types'
import { DateTime } from '../../utils'
import { Marquee } from '../Marquee/Marquee'
import {
  Container,
  CurrentTime,
  HorizontalMarqueeContainer,
  NewsFlashSeparator,
  StyledLinkHorizontalMarqueeIcon,
  StyledLinkNewsflashes,
  StyledLinkNewsflashesItem,
  StyledLinkVerticalMarqueeHeader,
  Title,
  VerticalMarqueeContainer,
} from './Newsflashes.styles'
import LeftArrowBlueIcon from '/public/assets/images/svg/left-arrow-blue.svg'
import NewsflashesCircleArrowBlueIcon from '/public/assets/images/svg/newsflashes-circle-arrow-blue.svg'
import { usePage } from '../../contexts'

interface Props {
  hideOnMobile?: boolean
  width?: number
  newsFlashColor?: string
  newsFlash?: INewsflash[]
}

export const Newsflashes = ({
  hideOnMobile,
  width,
  newsFlashColor,
  newsFlash,
}: Props) => {
  const page = usePage()
  const isDarkTheme = page?.isDarkTheme || false
  const [newsflashes, setNewsflashes] = useState<INewsflash[] | undefined>(
    newsFlash
  )
  const [newsFlashesSpeed, setNewsFlashesSpeed] = useState({
    newsflashHorizontalSpeed: 40,
    newsflashVerticalSpeed: 20,
  })

  useEffect(() => {
    let unmounted = false

    if (!newsFlash || newsFlash.length === 0) {
      ;(async () => {
        const newsFlashesSpeed = await clientApi.getNewsFlashesSpeed()
        setNewsFlashesSpeed(newsFlashesSpeed)
        const news = await clientApi.getNewsflashes()
        if (unmounted) {
          return
        }
        setNewsflashes(news)
      })()

      return () => {
        unmounted = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const duplicateDate = (newsflashes: INewsflash[] | undefined) => {
    let news = newsflashes

    if (!news || !news.length) {
      return
    }

    while (news.length < 6) {
      news = news.concat(news)
    }

    return news
  }

  const renderNewsflashes = () => {
    const news = duplicateDate(newsflashes)
    return (
      news?.slice(0, 8).map((item, index) => (
        <div key={`${index}-${item.id}`}>
          <StyledLinkNewsflashesItem
            href={newsflashesPageUrl}
            newsFlashColor={newsFlashColor}
          >
            <time>{DateTime.parse(item.time)?.toTime()}</time>
            {item.text}
          </StyledLinkNewsflashesItem>
          <NewsFlashSeparator />
        </div>
      )) ?? []
    )
  }

  const newsflashesPageUrl = '/news/news-flash'

  return (
    <Container hideOnMobile={hideOnMobile} width={width}>
      <HorizontalMarqueeContainer>
        <Marquee
          speed={newsFlashesSpeed.newsflashHorizontalSpeed}
          directionReverse={false}
          axis={'X'}
          pauseOnHover={true}
          height={'42px'}
          width={'100%'}
        >
          {renderNewsflashes()}
        </Marquee>
        <StyledLinkHorizontalMarqueeIcon
          href={newsflashesPageUrl}
          newsFlashColor={newsFlashColor}
          isDarkTheme={isDarkTheme}
        >
          <NewsflashesCircleArrowBlueIcon />
        </StyledLinkHorizontalMarqueeIcon>
      </HorizontalMarqueeContainer>
      <VerticalMarqueeContainer>
        <StyledLinkVerticalMarqueeHeader
          href={newsflashesPageUrl}
          backgroundColor={newsFlashColor}
        >
          <Title>{he.newsflashes}</Title>
          <CurrentTime>{DateTime.localNowToFormattedDate()}</CurrentTime>
        </StyledLinkVerticalMarqueeHeader>
        <Marquee
          speed={newsFlashesSpeed.newsflashVerticalSpeed}
          directionReverse={true}
          axis={'Y'}
          pauseOnHover={true}
          height={'100%'}
          width={width ? `${width}px` : '230px'}
        >
          {renderNewsflashes()}
        </Marquee>
        <StyledLinkNewsflashes
          href={newsflashesPageUrl}
          newsFlashColor={newsFlashColor}
        >
          {he.toAllNewsflashes}
          <LeftArrowBlueIcon />
        </StyledLinkNewsflashes>
      </VerticalMarqueeContainer>
    </Container>
  )
}

export default Newsflashes
